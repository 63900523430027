import * as React from 'react'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import { booleans, selects } from '../Restrictions.model'
import { RightsType } from 'services/cmAPI/getCustomer'
import CreateInputElement from './CreateInputElement'
function Rights({ data, search }: { data: RightsType; search: string }) {
  // Search filter
  const searchFilter = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }
  const alphabeticalSort = (item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }
  const sortedBooleans = booleans.sort(alphabeticalSort)
  const sortedSelects = selects.sort(alphabeticalSort)
  return (
    <Paper sx={{ width: '100%' }} elevation={3}>
      <Stack sx={{ padding: '5rem' }} spacing={3} alignItems="center">
        <Typography variant="h3">Rights</Typography>
        <Stack sx={{ width: '100%' }}>
          <Grid container spacing={2} sx={{ marginBottom: '4rem' }}>
            {sortedBooleans.filter(searchFilter).map((right) => (
              <Grid key={right.key} item xs={4}>
                <CreateInputElement
                  key={right.key}
                  element={right}
                  data={data}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            {sortedSelects.filter(searchFilter).map((right) => (
              <Grid key={right.key} item xs={6}>
                <CreateInputElement
                  key={right.key}
                  element={right}
                  data={data}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default Rights
