import React from 'react'
import { Stack, Card, CardContent, Typography } from '@mui/material'
import StaticItem from './StaticItem'
import { useParams } from 'react-router'
import { useCustomerData } from 'services/queries/useCustomerData'

export const StaticModel = [
  { title: 'Name', field: 'name' },
  { title: 'ID', field: 'id' },
  { title: 'Local ID', field: 'local_id' },
  { title: 'Created at', field: 'created_at', isDate: true },
  { title: 'Expire', field: 'expires_at', isDate: true },
]

const Static = () => {
  const { id: selectedCustomer } = useParams()
  const { data } = useCustomerData(selectedCustomer)
  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Company
        </Typography>
        <Stack
          direction="column"
          spacing={4}
          mt={4}
          justifyContent={'space-evenly'}
        >
          {StaticModel.map((item) => (
            <StaticItem
              key={item.title}
              data={data[item.field]}
              item={item}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Static
