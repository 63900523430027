import React from 'react'
import { Header, Button, ActionDialog, Stack } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import NewCustomer from './NewCustomer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ExportUsers from './ExportUsers'
import { useUserAuth } from 'contexts/googleAuth'
import { useRisikaUsers } from 'services/queries/useRisikaUsers'
import { useUsers } from 'services/queries/useUsers'
import { useCustomerData } from 'services/queries/useCustomerData'
import { useNavigate } from 'react-router-dom'
import { useMatch, useLocation } from 'react-router-dom'
import NewUserSetting from './NewUserSetting'
import { userAllowedToAddNewSettings } from 'constants'
import { Box, Tooltip } from '@mui/material'

const MainHeader = () => {
  const { logOut, user } = useUserAuth()
  const userEmail = user?.email
  const location = useLocation()
  const path = useMatch('/customer/:id', location.pathname)
  const navigate = useNavigate()

  const params = path?.params
  const selectedCustomer = params?.id || null

  const { data: risikaUsers } = useRisikaUsers()
  const { data: usersData, isLoading: usersLoading } =
    useUsers(selectedCustomer)

  const { data: customerData, isLoading: customerLoading } =
    useCustomerData(selectedCustomer)
  const canIAddNewSettings = userAllowedToAddNewSettings.includes(userEmail)
  return (
    <Header title={<FormattedMessage id={intl.customerManagement('title')} />}>
      <Stack spacing={1}>
        <ActionDialog
          title={
            <FormattedMessage
              id={intl.customerManagement('new-customer-title')}
            />
          }
          removeActions
          content={(close) => (
            <NewCustomer risikaUsers={risikaUsers?.data} closeFn={close} />
          )}
          maxWidth="sm"
          fullWidth={true}
          agreeText={<FormattedMessage id={intl.generic('create')} />}
          render={(open) => (
            <Button onClick={open} icon={<FontAwesomeIcon icon={faPlus} />}>
              Create New Company
            </Button>
          )}
        />
        <ActionDialog
          title={'Add new user setting to all users'}
          removeActions
          content={(close) => <NewUserSetting />}
          maxWidth="sm"
          fullWidth={true}
          agreeText={<FormattedMessage id={intl.generic('create')} />}
          render={(open) =>
            canIAddNewSettings ? (
              <Button onClick={open} icon={<FontAwesomeIcon icon={faPlus} />}>
                Add new user setting
              </Button>
            ) : (
              <Tooltip title="Oops you can't add new settings. If you think this is a mistake, get in contact with the frontend team.">
                <Box>
                  <Button
                    disabled={true}
                    onClick={open}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    Add new user setting
                  </Button>
                </Box>
              </Tooltip>
            )
          }
        />
        {!usersLoading && !customerLoading && !!usersData ? (
          <ExportUsers customerData={customerData} usersData={usersData} />
        ) : null}
        {selectedCustomer ? (
          <Button onClick={() => navigate('/')}>Back</Button>
        ) : null}
        <Button onClick={() => logOut()}>Log Out</Button>
      </Stack>
    </Header>
  )
}

export default MainHeader
