import { updateCustomer } from '../cmAPI/updateCustomer'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'

export function useUpdateCustomer() {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateCustomer, {
    onSuccess: async ({ data }, variables) => {
      const customerId = data.id
      await queryClient.refetchQueries('getCustomer' + customerId)
      await queryClient.refetchQueries('getCustomerPlan' + customerId)
      enqueueSnackbar('User is updated', {
        variant: 'success',
        autoHideDuration: 3000,
      })
    },
    onError: (err) => {
      enqueueSnackbar('Failed to update', {
        variant: 'error',
        autoHideDuration: 3000,
      })
      console.error('updateCustomer failed: ', err)
    },
  })
}
