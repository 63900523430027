import * as React from 'react'
import { FormGroup, TextField } from '@mui/material'
import { FormControlLabel } from '@material-ui/core'
import numeral from 'numeral'
import { useDebounce } from 'react-use'
import { useSnackbar } from 'notistack'

function CustomNumeric({
  value,
  max,
  label,
  onChange,
  isLoading,
  helperWord,
}: {
  value: number
  max: number
  label: string
  onChange: (newValue) => void
  isLoading: boolean
  helperWord?: string
}) {
  const format = (value) => {
    return numeral(value).format('0,0')
  }
  const [val, setVal] = React.useState(value)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [,] = useDebounce(
    () => {
      if (isUpdating === false) return
      if (val && val < value) {
        setIsUpdating(false)
        enqueueSnackbar(
          'Please make sure the Max users value is greater than the current users count',
          { variant: 'error' },
        )
        return
      }

      setIsUpdating(false)
      onChange(val)
    },
    1500,
    [val],
  )

  return (
    <FormGroup>
      <FormControlLabel
        labelPlacement="top"
        control={
          <TextField
            disabled={isLoading}
            fullWidth
            onChange={(e) => {
              setIsUpdating(true)
              setVal(parseInt(numeral(e.target.value).format('0')))
            }}
            value={format(val)}
            variant="outlined"
            helperText={
              helperWord
                ? `${helperWord}: ${format(
                  label === 'Monitoring Count' ? value - max : max,
                )}`
                : ''
            }
          />
        }
        label={isUpdating ? 'Updating...' : label}
      />
    </FormGroup>
  )
}

export default CustomNumeric
