import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Box,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { useParams } from 'react-router'
import { useGetSelectedCustomerData } from 'Pages/SelectedCustomer/SelectedCustomer.controller'
import {
  findExceptions,
  handleUpdateRestrictions,
} from '../../Restrictions.controller'
import Exception from './Exception'
import { ExceptionType } from '../../Restrictions.types'
import { useUpdateCustomer } from 'services/queries/useUpdateCustomer'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function RestoreDefaultsDialog() {
  const [open, setOpen] = React.useState(false)
  const { id: selectedCustomer } = useParams()
  const { data, isLoading } = useGetSelectedCustomerData(selectedCustomer)
  const { customerPlanData, plansData } = data
  const { mutate: updateCustomer } = useUpdateCustomer()
  const currentPlan = customerPlanData.subscription_plan

  const defaultValuesForPlan = plansData.find(
    (plan) => plan.name === currentPlan,
  )
  const exceptions = findExceptions(customerPlanData, defaultValuesForPlan)

  const restoreException = (exception: ExceptionType) => {
    switch (exception.type) {
      case 'right':
        handleUpdateRestrictions({
          selectedCustomer,
          updateCustomer,
          newValue: null,
          element: {
            updateLocation: exception.isGlobal ? 'global' : null,
            key: exception.key,
          },
        })
        break
      case 'cap':
        handleUpdateRestrictions({
          selectedCustomer,
          updateCustomer,
          newValue: null,
          element: {
            key: exception.key,
          },
        })
        break
      case 'country':
        handleUpdateRestrictions({
          selectedCustomer,
          updateCustomer,
          newValue: null,
          element: {
            updateLocation: exception.key,
            key: 'score_models',
          },
        })
        break

      default:
        break
    }
  }
  return (
    <Box
      sx={{
        height: 1,
        width: '100%',
      }}
    >
      <Button
        color="secondary"
        disabled={isLoading}
        variant="contained"
        onClick={() => setOpen(true)}
        fullWidth
        sx={{ height: '100%' }}
      >
        Restore Defaults
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{'Restore defaults'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={5}>
            <Grid sx={{ marginBottom: 5 }} item xs={3}>
              <Typography variant="h4">Field</Typography>
            </Grid>
            <Grid sx={{ marginBottom: 5 }} item xs={3}>
              <Typography variant="h4">Current</Typography>
            </Grid>
            <Grid sx={{ marginBottom: 3 }} item xs={3}>
              <Typography variant="h4">Default</Typography>
            </Grid>
            <Grid sx={{ marginBottom: 3 }} item xs={3}>
              <Typography variant="h4"> </Typography>
            </Grid>
            {exceptions.map((exception) => (
              <Exception
                key={exception.key}
                exception={exception}
                handleRestore={(exception) => restoreException(exception)}
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default RestoreDefaultsDialog
