import * as React from 'react'
import Button from './Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import colors from 'styles/colors'
import { Stack } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import RootTheme from 'styles/RootTheme'

// type ActionDialogProps = {|
//   title?: Node,
//   content: Function | Node,
//   action?: Function,
//   render: Function,
//   removeActions?: boolean,
//   disabled?: boolean,
//   cancelText?: Node,
//   agreeText?: Node,
//   maxWidth?: string,
//   fullWidth?: boolean,
//   onClose?: Function,
// |}

const ActionDialog = (props) => {
  const [open, setOpen] = React.useState(false)

  const {
    title,
    content,
    action,
    render,
    removeActions,
    disabled = false,
    cancelText = <FormattedMessage id={intl.confirmAction('cancel')} />,
    agreeText = <FormattedMessage id={intl.confirmAction('agree')} />,
    maxWidth,
    fullWidth = false,
    onClose,
  } = props

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const handleAction = () => {
    handleClose()

    if (typeof action === 'function') {
      action()
    }
  }
  return (
    <>
      <RootTheme>
        {render(handleClickOpen)}
        <Dialog
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{ backgroundColor: colors?.risika, color: 'white' }}
            id="alert-dialog-title"
          >
            {/* <Typography variant="h5">{title}</Typography> */}
            {title}
          </DialogTitle>
          <DialogContent>
            {typeof content === 'function' ? content(handleClose) : content}
          </DialogContent>
          {!removeActions ? (
            <DialogActions style={{ padding: 2 }}>
              <Stack spacing={1}>
                <Button
                  data-cy="action-dialog-cancel"
                  onClick={handleClose}
                  variant={null}
                >
                  {cancelText}
                </Button>
                <Button
                  data-cy="action-dialog-agree"
                  onClick={handleAction}
                  color="primary"
                  autoFocus
                  disabled={disabled}
                >
                  {agreeText}
                </Button>
              </Stack>
            </DialogActions>
          ) : (
            ''
          )}
        </Dialog>
      </RootTheme>
    </>
  )
}

export default ActionDialog
