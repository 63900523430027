import React from 'react'
import Changeable from './Changeable'
import { Stack } from '@mui/material'
import Static from './Static'
import ColorfulManageGroup from './ColorfulManageGroup'
import { theme5 } from 'styles/theme5'

const GeneralInformation = ({ planNames, customerData, customerPlanData }) => {
  return (
    <Stack
      p={2}
      sx={{
        bgcolor: theme5.palette.grey[100],
        width: '100%',
      }}
      spacing={3}
      direction='row'
    >
      <Static />

      <Stack
        direction="column"
        justifyContent="space-evenly"
        flexWrap="wrap"
        rowGap={theme5.spacing(2)}
        pb={3}
      >
        <Changeable
          customerData={customerData}
          customerPlanData={customerPlanData}
          planNames={planNames}
        />
        <ColorfulManageGroup customerData={customerData} />
      </Stack>
    </Stack>
  )
}

export default GeneralInformation
