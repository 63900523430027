import { Box, Tooltip, Typography, Grid } from '@mui/material'
import { Show } from 'components'
import CustomSwitch from './CustomSwitch'

type Value = {
  model: string
  explanation?: boolean
}
function ScoreModels({
  value,
  label,
  onChange,
  isLoading,
  options,
  availableModels,
}: {
  value: Value[]
  label: string
  onChange: (newValue: Value[]) => void
  isLoading: boolean
  options: {
    label: string
    key: string | number
  }[]
  availableModels: { model: string; canBeChanged: boolean }[]
}) {
  const handleUpdate = (
    field: 'model' | 'explanation',
    curValue: boolean,
    model: string,
  ) => {
    switch (field) {
      case 'model':
        if (!curValue) {
          if (model === 'BOHR') {
            onChange([...value, { model, explanation: false }])
          } else {
            onChange([...value, { model }])
          }
        } else {
          onChange(value.filter((x) => x.model !== model))
        }
        break
      case 'explanation':
        break

      default:
        break
    }
  }

  const handleValue = (value: Value[], model) => {
    if (value) {
      const modelValue = value.map((x) => x.model)
      return modelValue.includes(model)
    }
  }

  const checkIfDisabled = (model) => {
    const modelValue = availableModels.filter((x) => x.model === model)[0]
    return !modelValue.canBeChanged
  }

  return (
    <Show when={value}>
      <Grid container direction={'row'} spacing={2}>
        {availableModels.length ? (
          availableModels.map(({ model }) => (
            <Grid item xs={6} key={model}>
              <Typography variant="h5">{model}</Typography>
              <Tooltip
                placement="top"
                title={
                  checkIfDisabled(model) ? 'You can not change this model' : ''
                }
              >
                <Box display="flex">
                  <CustomSwitch
                    isLoading={isLoading}
                    // disabled={checkIfDisabled(model)}
                    onChange={() =>
                      handleUpdate('model', handleValue(value, model), model)
                    }
                    value={handleValue(value, model)}
                    label="Model"
                  />
                </Box>
              </Tooltip>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4">
              No available properties at this time
            </Typography>
          </Grid>
        )}
      </Grid>
    </Show>
  )
}

export default ScoreModels
